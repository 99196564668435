.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
}
.header-content {
	background: var(--primary-btn);
	width: 100%;
	height: 300px;
	border-radius: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;
	padding: 0 5rem;
}
.header-content-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 0.5rem;
	width: 100%;
}
.header-content h1 {
	font-weight: bold;
	font-size: 72px;
	color: #ffffff;
}
.header-content h2 {
	font-weight: 600;
	font-size: 28px;
	color: #ffffff;
}
.header-content h3 {
	font-weight: 300;
	font-size: 20px;
	color: #ffffff;
}
.header-content img {
	height: 70%;
	margin-right: -1rem;
}

.shake-vertical {
	-webkit-animation: shake-vertical 10s cubic-bezier(0.455, 0.03, 0.515, 0.955) 2s infinite both;
	animation: shake-vertical 10s cubic-bezier(0.455, 0.03, 0.515, 0.955) 2s infinite both;
}
@-webkit-keyframes shake-vertical {
	0%,
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	10%,
	30%,
	50%,
	70% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
	}
	20%,
	40%,
	60% {
		-webkit-transform: translateY(8px);
		transform: translateY(8px);
	}
	80% {
		-webkit-transform: translateY(6.4px);
		transform: translateY(6.4px);
	}
	90% {
		-webkit-transform: translateY(-6.4px);
		transform: translateY(-6.4px);
	}
}
@keyframes shake-vertical {
	0%,
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	10%,
	30%,
	50%,
	70% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
	}
	20%,
	40%,
	60% {
		-webkit-transform: translateY(8px);
		transform: translateY(8px);
	}
	80% {
		-webkit-transform: translateY(6.4px);
		transform: translateY(6.4px);
	}
	90% {
		-webkit-transform: translateY(-6.4px);
		transform: translateY(-6.4px);
	}
}

/* media  */
@media screen and (max-width: 1200px) {
	.header-content {
		padding: 0 3rem;
	}
	.header-content h1 {
		font-size: 60px;
		line-height: 72px;
	}
	.header-content h2 {
		font-size: 24px;
		line-height: 36px;
	}
	.header-content h3 {
		font-size: 16px;
		line-height: 24px;
	}
	.header-content img {
		height: 50%;
	}
}

@media screen and (max-width: 900px) {
	.header-content {
		padding: 0 2rem;
	}
	.header-content h1 {
		font-size: 48px;
		line-height: 60px;
	}
	.header-content h2 {
		font-size: 20px;
		line-height: 30px;
	}
	.header-content h3 {
		font-size: 14px;
		line-height: 21px;
	}
}

@media screen and (max-width: 800px) {
	.header-content img {
		width: 50%;
	}
	.header-content {
		flex-direction: column;
		gap: 1rem;
		padding: 1rem 1rem;
		align-items: flex-end;
	}
	.header-content h1 {
		font-size: 36px;
		line-height: 48px;
	}
	.header-content h2 {
		font-size: 16px;
		line-height: 24px;
	}
	.header-content h3 {
		font-size: 12px;
		line-height: 18px;
	}
}

@media screen and (max-width: 550px) {
	.header-content {
		align-items: flex-end;
		padding: 2rem 2rem;
	}
	.header-content h1 {
		font-size: 28px;
		line-height: 24px;
		width: 100%;
	}
	.header-content h2 {
		font-size: 14px;
		line-height: 20px;
		width: 100%;
	}
	.header-content h3 {
		font-size: 12px;
		line-height: 18px;
		width: 100%;
	}
	.header-content img {
		width: 60%;
	}
}