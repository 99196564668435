.profile {
	padding: 0;
}
.profile-top {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.profile-banner {
	background: var(--primary-btn);
	width: 100%;
	height: 300px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding: 30px;
	border-radius: 25px;
}
.profile-pic {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 200px;
}
.profile-pic img {
	border: 6px solid var(--color-bg);
	border-radius: 50%;
	width: 70%;
	box-shadow: 3px 5px 6px rgba(0, 0, 0, 0.45);
}
.profile-pic h3 {
	font-weight: 600;
	font-size: 28px;
	line-height: 42px;
	text-align: center;
	color: #ffffff;
	padding-top: 20px;
}
.profile-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
}
.profile-container-text h1 {
	font-weight: 600;
	font-size: 28px;
	line-height: 42px;
	color: #ffffff;
	margin-bottom: 1rem;
}
.profile-container-card {
	margin: 0 -5px;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	width: 100%;
}

/* Media Queries */
@media screen and (max-width: 1800px) {
	.profile-container-card {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media screen and (max-width: 1300px) {
	.profile-container-card {
		grid-template-columns: repeat(4, 1fr);
	}
	.bids-card img {
		width: 300px;
		height: 200px;
	}
}

@media screen and (max-width: 900px) {
	.profile-container-card {
		grid-template-columns: repeat(3, 1fr);
	}
	.bids-card img {
		width: 300px;
		height: 200px;
	}
}

@media screen and (max-width: 768px) {
	.profile-container-card {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 550px) {
	.section__padding {
		padding: 2rem 14px !important;
	}
	.bids-card {
		margin-bottom: 10px;
		margin-inline: 5px;
	}
	.bids-card-top p {
		font-size: 12px;
		line-height: 18px;
		margin-top: 5px;
	}
	.bids-card-bottom p {
		font-size: 10px;
		line-height: 15px;
	}
	.profile-pic h3 {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
	}
	.profile.section__padding {
		padding: 1rem 0px !important;
	}
}

@media screen and (max-width: 400px) {
	.profile-container-card {
		grid-template-columns: repeat(1, 1fr);
	}
	.bids-card img {
		width: 100%;
		height: 150px;
	}
}
